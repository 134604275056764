.competition-item-main {
    width: 33%;
    padding: 20px;
    margin: 10px;
    border: 2px solid #ececec;
    border-radius: 24px;
}
.competition-item-child1-status-upcoming {
    padding: 5px 10px;
    width: max-content;
    background: #ffb127;
    border-radius: 10px;
}

.competition-item-child1-status-inprogress {
    padding: 5px 10px;
    width: max-content;
    background: #46A758;
    border-radius: 10px;
}

.competition-item-child1-status-finished {
    padding: 5px 10px;
    width: max-content;
    background: #6F6F6F;
    border-radius: 10px;
}

.competition-item-child2 {
    margin: 15px 0px;
    display: flex;
    background: #ececec;
    padding: 26px;
    border-radius: 24px;
}

.competition-item-child2-logo {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.competition-item-child3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

