.ref-footer-item-left1 {
    width: max-content;
    height: max-content;
    padding: 8px;
    margin: 2px 5px;
    background: #ececec;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ref-footer-item-left2 {
    background: #ececec;
    padding: 5px;
    border-radius: 100px;
}


.claim-btn {
    background-color: #29a738;
    padding: 5px 10px;
    border-radius: 50px;
    color: #fff;
}

.claim-btn:hover {
    background-color: #25952f;
}

.claimed {
    background-color: #d61b18;
    padding: 5px;
    border-radius: 50px;
    color: #fff;
}

/* BookingConfirmation.css */

.confirmation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
    max-width: 400px;
    margin: 40px auto;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .check-icon {
    font-size: 48px;
    color: #6fcf97; /* Light green color */
    border: 3px solid #6fcf97;
    border-radius: 50px;
  }
  
  .confirmation-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
  }
  
  .confirmation-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .claim-model-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 2px solid #007bff;
    color: #007bff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
  }
  
  .claim-model-button:hover {
    color: #0056b3;
    border: 2px solid #0056b3;

  }

  .no-model-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 2px solid red;
    color: red;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    margin-left: 10px;
  }
  
  .no-model-button:hover {
    color: #8B0000;
    border: 2px solid #8B0000;

  }
  
  
