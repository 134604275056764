.background-doc {
  position: relative;
  width: 100%;
  min-height: 100vh;
  top: 0px;
}

.content-doc {
  position: relative;
  z-index: 1;
  padding: 100px 20px;
}

h2 {
  font-size: 2rem;
}

h4 {
  margin: 0;
}

.conclude {
  color: #007bff;
  font-weight: bold;
}

.left-menu-doc {
  position: fixed;
  top: 100px;
  left: 0;
  box-sizing: border-box;
}

.menu-bars-docs {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px 5px;
  ;
  width: 45px;
  display: none;
  background-color: #ddd;
  display: none;
  position: fixed;
  top: 100px;
  left: 10px;
}

.right-docs {
  border-left: 1px solid #ddd;
  padding-left: 20px;
  min-height: 70vh;
}

.close-icon-docs {
  display: none;
}

.left-menu-doc-show {
  position: fixed;
  top: 100px;
  left: 0;
  box-sizing: border-box;
}
.prop-web {
  display: block;
}

.prop-mobile {
  display: none;
}
@media (max-width: 430px) and (max-height: 932px) {
  .content-doc {
    padding: 50px 20px;
  }

  .menu-bars-docs {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .left-menu-doc {
    display: none;
  }

  .left-menu-doc-show {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    /* Background overlay */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: slideIn 0.1s ease-out
  }

  .content-menu-docs {
    background-color: #fff;
    margin-top: 100px;
    width: 90%;
    height: 100%;
    padding-top: 20px;
  }

  .close-icon-docs {
    top: 95px;
    right: 50px;
    position: absolute;
    font-size: 30px;
    cursor: pointer;
    display: block;
  }

  .right-docs {
    border-left: none;
  }

  .prop-web {
    display: none;
  }

  .prop-mobile {
    display: flex;
  }

  ul {
    padding: 0;
  }

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      /* Start off-screen to the left */
      opacity: 0;
      /* Optional: fade in effect */
    }

    to {
      transform: translateX(0);
      /* End at its original position */
      opacity: 1;
      /* Fully visible */
    }
  }
}