.popup-box {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    /* Background overlay */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

}

.box {
    position: relative;
    width: 30%;

    /* Set a max height for the popup */
    background: #232323;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    /* Hide overflow on the popup itself */
}

.popup-content {
    /* Set a max height for the content */
    max-height: 70vh;
    overflow-y: auto;
    /* Enable vertical scrolling if the content exceeds the height */
    scrollbar-color: #a0a0a0 #232323;
    scrollbar-width: thin;
    text-align: start;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
}

.btn-forget-pw {
    padding: 20px;
    background-color: #2f2f2f;
    font-size: 20px;
    border-radius: 10px;
}

.btn-forget-pw:hover {
    background-color: #504f4f;
}

.btn-login {
    padding: 20px;
    background-color: #3e63dd;
    font-size: 20px;
    border-radius: 10px;
}

.btn-login:hover {
    background-color: #4d6edc;
}

.content-login {
    padding: 20px;
    background-color: #2f2f2f;
    border-radius: 10px;
    text-align: start;
    margin-bottom: 20px;
    color: white;
    #demo-simple-select-label {
        color: white;
    }

}

.content-user{
    padding: 10px;
    background-color: #3a3a3a;
    border-radius: 10px;
    margin-bottom: 20px;
}

.container {
    background-color: #2a2a2a;
    color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.title {
    font-size: 18px;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 14px;
    margin-bottom: 20px;
}

.packageList {
    margin-bottom: 20px;
}
.packageItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.packageText {
    margin-left: 10px;
    font-size: 20px;
}
.passwordContainer {
    display: flex;
    align-items: center;
    border: 1px solid #555;
    border-radius: 4px;
    padding: 5px;
    background-color: #1c1c1c;
}
.passwordInput {
    flex: 1;
    border: none;
    background-color: transparent;
    color: white;
    outline: none;
    padding: 10px;
    font-size: 20px;
    
}
.eyeButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
}