.avartar-item {
    background: white;
    padding: 15px;
    border-radius: 12px;
    height: auto;
}

.avartar-back {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 12px;
}

.avartar-back.active {
    background: #EDF6FF;
}

.avartar-back.inactive {
    background: #ffe26d;
}
