.kyc-container {
  width: 100%;
  padding: 10px 0;
}

.kyc-paper {
  padding: 15px;
  border-radius: 12px;
}

.kyc-step-content {
  min-height: 250px;
  padding: 15px 0;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 8px;
  border: 2px dashed #ccc;
  background-color: #f9f9f9;
  height: 180px;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
}

.upload-container:hover {
  border-color: #3e63dd;
  background-color: #f5f8ff;
}

.selfie-container {
  height: 180px;
}

.image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.preview-image {
  max-width: 100%;
  max-height: 120px;
  border-radius: 8px;
  object-fit: contain;
  margin-bottom: 5px;
}

.requirements-list {
  margin-top: 10px;
  padding-left: 20px;
  color: #666;
}

.requirements-list li {
  margin-bottom: 5px;
}

.twitter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border-radius: 8px;
  background-color: #f9f9f9;
  min-height: 150px;
}

.twitter-connect, .twitter-verified {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.kyc-status-approved, .kyc-status-pending {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px 15px;
  min-height: 300px;
}
