.background-container {
  width: 99vw;
  background-image: url('../../../public/images/bg-default.png');
  background-size: cover;
  min-height: 135vh;

}

.banner {
  z-index: 1;
  /* Ensure content is above the background */
  /* Optional: Add some padding around your content */
  width: 80vw;
  background-image: url('../../../public/images/banner.png');
  background-size: cover;
  margin-top: 100px;
}

.content-db {
  position: relative;
  z-index: 3;
  /* Ensure content is above the background */
  /* padding: 20px; */
  /* Optional: Add some padding around your content */
  /* padding-top: 40px; */
  top: 40px;
}


.web3 {
  border: #192c60 solid 1px;
  border-radius: 50px;
  padding: 5px 30px;
  background: linear-gradient(to right, #ffcb46 0%, #02c8e1 100%);
  background-clip: text;
  color: transparent;
  font-weight: bold;
  /* font-size: 25px; */
  font-size: 1.30vw;
  text-align: center;
}

.second-sidebar {
  color: #fff;
  /* font-size: 80px; */
  font-size: 3.8vw;
}

.second-sidebar-sub{
  font-size: 4.5vw;
  font-weight: bold;
}

.third-sidebar {
  font-size: 1.60vw;
}

.top-content {
  padding: 8.26vh 0 4.5vh 0;
  margin-top: 4vh;
}

.second-content {
  margin-bottom: 4vh;
}

.text-trade-top {
  font-size: 2.34vw;
  color: #fff;
}

.text-trade-down {
  margin-top: -20px;
  color: #757b7e;
  font-size: 1.3vw;
}

.left-train {
  background: linear-gradient(to right, #09191f, #008ef9);
  /* Từ xanh nhạt đến xanh đậm */
  width: 70%;
  height: 2px;

}

.right-train {
  background: linear-gradient(to left, #09191f, #008ef9);
  /* Từ xanh nhạt đến xanh đậm */
  width: 70%;
  height: 2px;
  /* Chiều cao của phần tử */
}

.balance-btn {
  background-color: #2f2f30;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
}

.balance-btn.active {
  background-color: #fff;
  color: #000;
}

.balance-btn.unactive {
  background-color: #2f2f30;
  color: #fff;
}

.balance-btn-disabled {
  background-color: #161617;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
}

.compare-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  background-color: #0091ff;
  border-radius: 10px;
  cursor: pointer;
}


.step-btn {
  min-width: auto;

}

.step-btn:hover {
  border: #0091ff solid 1px;
  border-radius: 10px;
}

.step-header {
  font-size: 30px;
  color: #fff;
  height: 120px;
  align-content: center;
}

.step-item {
  height: 60px;
  align-content: center;
  padding: 0 30px;
}

.step-item-sub {
  background-color: #101012;
  height: 60px;
  align-content: center;
  padding: 0 30px;
}



.start-challenge {
  padding: 10px;
  background-color: #0091ff;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

.support-btn {
  position: fixed;
  bottom: 120px;
  right: 30px;
  z-index: 99;
  font-size: 15px;
  border: none;
  outline: none;
  background-color: #0091ff;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 50px;
}

.feeFunding {
  position: relative;
}

.imgSaleDashboard {
  position: absolute;
  top: -50px;
  right: 30px;
  z-index: 10;
  width: 30%;
}

.ChallengeX1K {
  padding: 1.39vh 1.90vw;
  border-radius: 7px;
  border: none;
  background-color: #0091ff;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.78vw;
  width: 15%;
}

.FreeTrial {
  padding: 1.39vh 2.86vw;
  border-radius: 7px;
  /* Rounded corners with 7px radius */
  border: none;
  /* No border */
  background-color: rgba(240, 240, 240, 0.3);
  /* Semi-transparent light gray background */
  cursor: pointer;
  /* Pointer cursor for interactivity */
  font-weight: bold;
  /* Bold font style */
  font-size: 0.78vw;
  width: 21%;

}

.evaluation-process-title {
  font-size: 1.5vw;
  color: #fff;
  margin-top: 20px;

}

.evaluation-process-img {
  width: 100%;
}
/* 
.step {
  width: 25%;
} */

.description-icon {
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 200px;
}
.evaluation-process-rate-sub {
  font-size: 0.7vw !important;
  margin-right: 5px;
  color: #FF8C00;
}

.step-main {
  display: flex;
}

.web {
  display: block;
}

.mobile {
  display: none;
}

.row-first {
  height: 150px !important;
}
.step-col {
  width: 25%;
}
.step-col-instant {
  width: 50%;
  
}
@media (max-width: 375px)  {
  .background-container {
    background-size: 100vw 100vh;
    /* Hình nền sẽ phủ đầy toàn bộ màn hình */
    background-position: bottom;
    /* Đảm bảo hình nền luôn nằm ở giữa */
    background-repeat: no-repeat;
    /* Không lặp lại hình nền */
    min-height: 130vh;
    
  }
  .step-col {
    width: 220px !important;
    border: 1px solid #0091ff;
    border-radius: 10px;
    margin-left: 2px;
  }
  .step-col-instant{
    width: 220px !important;
    border: 1px solid #0091ff;
    border-radius: 10px;
    margin-left: 2px;
  
  }
  .trading-cycle {
    height: 250px !important;
  }

  .banner {
    background-size: 130vw 55vh;
    /* Hình nền sẽ phủ đầy toàn bộ màn hình */
    background-position: bottom;
    /* Đảm bảo hình nền luôn nằm ở giữa */
    background-repeat: no-repeat;
    /* Không lặp lại hình nền */
    margin-top: 25px;
    background-image: none;

  }
  .second-sidebar{
    font-size: 7vw;
  }
  .second-sidebar-sub{
    font-size: 7.5vw;
  }
  .height-step-first {
    height: 250px !important;
  }

  /* .step-fisrt {
    display: none;
  } */

  /* .col-label-table {
    display: none !important;
  } */
}

@media (min-width: 376px)  and (max-width: 932px) {
  .background-container {
    background-size: 100vw 100vh;
    /* Hình nền sẽ phủ đầy toàn bộ màn hình */
    background-position: top;
    /* Đảm bảo hình nền luôn nằm ở giữa */
    background-repeat: no-repeat;
    /* Không lặp lại hình nền */
    min-height: 120vh;
  }
  .step-main {
    width: 100% !important;
  }
  .banner {
    background-size: 130vw 55vh;
    /* Hình nền sẽ phủ đầy toàn bộ màn hình */
    background-position: top;
    /* Đảm bảo hình nền luôn nằm ở giữa */
    background-repeat: no-repeat;
    /* Không lặp lại hình nền */
    margin-top: 25px;
    background-image: none;

  }
  .second-sidebar{
    font-size: 7vw;
  }
  .second-sidebar-sub{
    font-size: 7.5vw;
  }
  .height-step-first {
    height: 200px !important;
  }
}

@media (max-width: 932px) {
  .content-db {
    margin-top: 20px;
  }
  .step-main {
    width: 100% !important;
  }
  .web3 {
    padding: 10px 20px;
    font-size: 4vw;
  }

  .third-sidebar {
    font-size: 2.5vw;
  }
  .text-trade-top {
    font-size: 7vw;
  }

  .text-trade-down {
    font-size: 4.5vw;
    margin-top: -5px;
  }

  .top-content {
    margin-top: 0;
    padding: 5vh 0 2vh 0;
  }

  .ChallengeX1K {
    font-size: 4vw;
    width: 100%;

  }

  .FreeTrial {
    font-size: 4vw;
    width: 100%;
  }

  .left-train {
    width: 40%;
  }

  .right-train {
    width: 40%;
  }

  .evaluation-process {
    font-size: 10vw;
    text-align: center;
  }

  .evaluation-process-title {
    font-size: 7vw;
  }

  .evaluation-process-img {
    width: 60%;
  }

  .evaluation-process-target {
    font-size: 5vw !important;
  }

  .evaluation-process-rate {
    font-size: 5vw !important;
    height: 75%;
  }

  
  .evaluation-process-rate-sub {
    font-size: 4vw !important;
  }

  .evaluation-process-content {
    font-size: 4vw !important;
  }

  .balance-btn {
    padding: 8px 10px !important;
    font-size: 4vw !important;
  }

  .compare-btn {
    font-size: 2vw !important;
    text-align: center;
    padding: 8px 10px !important;
  }

  .step-btn {
    min-width: 60vw;
    border: #0091ff solid 1px;
    border-radius: 10px;
    margin-right: 10px;
  }

  /* .step-fisrt {
    min-width: 20vw;
  } */


  /* .content-table {
    display: none;
  } */

  .step-main {
    overflow: auto;
  }

  .web {
    display: none;
  }
  
  .mobile {
    display: block;
  }

  .imgSaleDashboard {
    right: 0px;
    width: 40%;
  }
}