.background-sup {
    position: relative;
    width: 100%;
    background-image: url('../../../public/images/bg-default.png');
    background-size: cover;
    min-height: 120vh;
    top: 0px;
    padding-bottom: 100px;
  }
  
  .content-sup {
    position: relative;
    z-index: 1;
    padding-top: 120px;
  }
  

  .item-support {
    padding: 20px 0;
    border-bottom: #ccc solid 1px;
    font-size: 20px;
  }