.account-status {
    margin: 3px;
    padding: 3px 8px;
    border-radius: 4px;
    background: #46A758;
    color: white;
}

.trading-cycle-item {
    width: 100%;
    display: flex;
    background: #ececec;
    padding: 11px;
    margin: 5px 0px 5px 0px;
    border-radius: 5px;
}

.start-item {
    width: 20%;
    background: #F0F4FF;
    margin-right: 30px;
    padding: 20px;
    border-radius: 24px;
}