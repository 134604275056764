.background-lauchapp {
  position: relative;
  width: 100%;
  background-image: url('../../../public/images/bg-default.png');
  background-size: cover;
  min-height: 120vh;
  top: 0px;
}

.right-app {
  padding: 20px;
  border-radius: 10px;
  background-color: #232323;
}

.left-app {
  padding: 20px;
  border-radius: 10px;
  background-color: #232323;
}

.button-trading {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
}

.button-trading.active {
  background-color: #000;
}

.button-market {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
}

.button-market.active {
  background-color: #343434;
}

.input-market {
  background-color: #1c1c1c;
  border: none;
  color: #fff;
  font-size: 30px;
  width: 100%;
}

.input-market:focus {
  outline: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

/* Rounded slider */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.button-lost {
  padding: 10px 0;
  border-radius: 10px;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
  background-color: #343434;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-lost.active {
  background-color: #ffffff;
  color: #000;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #777;
}

.slider-labels span {
  position: relative;
}

.usdx {
  display: flex;
  font-size: 23px;
  color: white;
  align-items: center;
}

.time-url {
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 20px;
}

.time-url.active {
  background-color: #343434;
}

#tradingview_ea748-wrapper {
  width: 1000px;
}

.enter-amount {
  margin-top: 20px;
  padding: 20px;
  background-color: #3e63dd;
  color: #fff;
  font-size: 20px;
  border-radius: 20px;
}

.enter-amount:hover {
  background-color: #4d6edc;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  /* Lớp mờ màu đen, 70% trong suốt */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  /* Phủ lên mọi nội dung khác */
  font-size: 2rem;
  font-family: Arial, sans-serif;
}

.invisible {
  display: none;
}

.visible {
  display: block;
}

.chart-trade-mobile {
  display: none;
}

.trade-mobile {
  display: block;
}

.button-visible-trading {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: center;
}

.button-visible-trading.active {
  background-color: #5a5959;
}

.laptop-icon {
  color: #fff;
  margin-top: 20px;
  width: 40%;
}

.favorite-trading-active {
  color: #ffd700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorite-trading-active:hover {
  color: #f9e361
}

.favorite-trading-inactive {
  color: #ffd700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorite-trading-inactive:hover {
  color: #f9e361
}

.star-icon {
  font-size: 17px;
}
@media (max-width: 430px) and (max-height: 932px) {
  .chart-trade-mobile {
    display: flex;
    margin-top: 20px;
  }

  .trade-mobile {
    display: none;
  }

  .trade-mobile-show {
    display: block;
  }

  .left-app {
    overflow: auto;
  }

  .top-chart {
    width: 100%;
    overflow: auto;
  }
}

/* Ẩn nút tăng/giảm trên Chrome, Edge, và Safari */
input[type="number"].no-spinner::-webkit-inner-spin-button,
input[type="number"].no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Ẩn nút tăng/giảm trên Firefox */
input[type="number"].no-spinner {
    -moz-appearance: textfield;
}
