.payout-item1 {
    display: flex;
    margin: 5px 20px;
    padding: 20px;
    background: #F0F4FF;
    border-radius: 24px;
}

.payment-item2 {
    margin: 5px 20px;
    padding: 20px;
    border-radius: 24px;
    border: 3px solid #ececec;
    height: 200px;
}

.payout-link-share {
    width: max-content;
    height: max-content;
    padding: 7px 13px;
    margin: 4px;
    background: #ececec;
    border-radius: 8px;
}

.payout-link-item1 {
    display: flex;
    background: #ececec;
    padding: 5px 10px;
    margin: 7px 0px;
    border-radius: 8px;
}

.pauout-tab4-item {
    width: max-content;
    height: max-content;
    padding: 9px;
    margin: 5px 10px;
    background: #ececec;
    border-radius: 12px;
    display: flex;
    align-items: center;
}

.pauout-tab4-item-logo-img {
    width: 20px;
    height: 20px;
}