.leaderboard-item1 {
    background: #ececec;
    margin: 0px 15px;
    padding: 6px 14px;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
}

.leaderboard-item1:hover {
    background: #3451B2;
    color: white;
}