.popup-box-noti {
    position: fixed;
    background: fff;
    /* Background overlay */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.box-noti {
    position: relative;
    width: 40%;

    /* Set a max height for the popup */
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    overflow: hidden;
    /* Hide overflow on the popup itself */
}

.popup-content {
    /* Set a max height for the content */
    max-height: 70vh;
    overflow-y: auto;
    /* Enable vertical scrolling if the content exceeds the height */
    scrollbar-color: #a0a0a0 #232323;
    scrollbar-width: thin;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
}

.title-noti:hover {
    cursor: pointer;
    text-decoration: underline;
}