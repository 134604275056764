.popup-box {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    /* Background overlay */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.box {
    position: relative;
    width: 30%;

    /* Set a max height for the popup */
    background: #232323;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    overflow: hidden;
    /* Hide overflow on the popup itself */
}

.popup-content {
    /* Set a max height for the content */
    max-height: 70vh;
    overflow-y: auto;
    /* Enable vertical scrolling if the content exceeds the height */
    scrollbar-color: #a0a0a0 #232323;
    scrollbar-width: thin;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
}

.popup-confirm-style {
    position: absolute;
    /* Đặt vị trí tuyệt đối */
    top: 50%;
    /* Căn giữa theo trục dọc */
    left: 50%;
    /* Căn giữa theo trục ngang */
    transform: translate(-50%, -50%);
    /* Dịch chuyển để căn giữa hoàn hảo */
    width: 30%;
    /* Chiều rộng chiếm 30% */
    background-color: #232323;
    /* Màu nền xám đen */
    border: 2px solid #000;
    /* Viền dày 2px, màu đen */
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
    /* Hiệu ứng đổ bóng */
    padding: 0 20px;
    /* Khoảng cách bên trong: không có trên/dưới, 20px hai bên */
}

@media (max-width: 430px) and (max-height: 932px) {
    .box {
        width: 100%;
    }

    .popup-confirm-style {
        width: 90%;
        /* Chiều rộng chiếm 30% */
    }
}