.circle-box {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #3E63DD;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
}

.total_money {
  height: 151px;
  background: #F3F3F3;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.balance-item {
  width: 135px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000000C;
  margin: 0px 10px;
  border-radius: 5px;
  font-size: 16px;
}

.balance-item:hover {
  border: 1px solid #3e62e3;
}

.balance-item-choose {
  width: 135px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3e62e3;
  color: white;
  margin: 0px 10px;
  border-radius: 5px;
  font-size: 16px;
}

.payment-currency-item {
  width: max-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000000C;
  margin: 0px 10px;
  border-radius: 5px;
  padding: 6px 12px;
}

.payment-currency-item-choose {
  width: max-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3e62e3;
  color: white;
  margin: 0px 10px;
  border-radius: 5px;
  padding: 6px 12px;
}

.ord-status-fail {
  width: max-content;
  margin: 5px;
  padding: 5px;
  background: red;
  border-radius: 7px;
  color: white;
}

.ord-status-success {
  width: max-content;
  margin: 5px;
  padding: 5px;
  background: green;
  border-radius: 7px;
  color: white;
}

.ord-status-pending {
  width: max-content;
  margin: 5px;
  padding: 5px;
  background: orange;
  border-radius: 7px;
  color: white;
}

.imgSale{
  position: absolute;
  top: 300px;
  right: 0;
  z-index: 10;
  width: 6%;
}

.underline-text {
  text-decoration: underline;
  font-weight: bold;
}

.underline-text:hover {
  color: #41444b;
  font-weight: bold;

}

.title-terms{
  color: #549227;
}

.close-icon-terms {
  position: absolute;
  top: 0px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
}

.popup-content-terms {
  /* Set a max height for the content */
  max-height: 70vh;
  overflow-y: auto;
  /* Enable vertical scrolling if the content exceeds the height */
  scrollbar-color: #a0a0a0 #fff;
  scrollbar-width: thin;
}