.background-upgrade {
  position: relative;
  width: 100%;
  background-image: url('../../../public/images/bg-default.png');
  background-size: cover;
  min-height: 120vh;
  top: 0px;
}

.content-upgrade {
  position: relative;
  z-index: 1;
  padding: 6.25vw 3.125vw;
}

.choose-upgrade {
  border: #192c60 solid 1px;
  border-radius: 50px;
  padding: 5px 15px;
  background: linear-gradient(to right, #ffcb46 0%, #02c8e1 100%);
  /* display: flex; */
  background-clip: text;
  color: transparent;
  font-weight: bold;
  font-size: 20px;
  width: 85%;
  text-align: center;
}

.accout-size-items {
  background-color: #101012;
  padding: 35px;
  border-radius: 20px;
  color: #fff;
}

.accout-number-items {
  background-color: #232324;
  padding: 20px;
  border-radius: 50px;
  width: 70px;
  display: flex;
  text-align: center;
}

.item-capital {
  background-color: #030916;
  border: #333 solid 1px;
  border-radius: 30px;
  margin-top: 25px;
}

.top-item-capital {
  border-bottom: #333 solid 1px;
}

.text-capital {
  margin: 10px 0;
}

.img-capital {
  max-width: 150px;
  max-height: 120px;
}

.toggle-buttons {
  display: flex;
  gap: 10px;
  padding: 15px 20px;
  border-radius: 40px;
  background-color: #070a14;
}

.button {
  padding: 20px 30px;
  border-radius: 30px;
  border: none;
  font-size: 20px;
  background-color: #333;
  color: #999;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: 600;
}

.button.active {
  background-color: #fff;
  color: #000;
}

.button:not(.active) {
  background-color: #222;
  color: #555;
}

.button:hover {
  background-color: #444;
}

.itemExam {
  padding: 30px 0;
  padding-left: 30px;
}

.itemExamFisrt {
  padding: 50px 0;
  padding-left: 30px;
}

.itemExam.active {
  background-color: #101012;
}

.itemExam:not(.active) {}

.itemExamFisrt.active {
  background-color: #101012;
}


.toggle-buttons-bal {
  display: flex;
  gap: 10px;
  padding: 15px 0;
  border-radius: 40px;
  /* background-color: #070a14; */
}

.balance-btn {
  background-color: #2f2f30;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
}

.balance-btn.active {
  background-color: #fff;
  color: #000;
}


.balance-btn:hover {
  background-color: #444;
  color: #fff;

}

.compare-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  background-color: #0091ff;
  border-radius: 10px;
  cursor: pointer;
}

.stage {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 50px;
  color: #000;
  font-size: 20px;
}

.stage.active {
border: #0091ff solid 3px;

}



.text-stage {
  color: #a0a0a0;
  font-size: 15px;
}

.text-stage.active {
  color: #0091ff;
  font-size: 20px;
}

.text-stage:not(.active) {
  color: #a0a0a0;
}

.payout-container {
  /* display: flex;
  justify-content: space-between; */
  align-items: center;
  background-color: #030d19;
  padding: 20px;
  border-radius: 16px;
  color: white;
  width: 100%;
  margin-top: 30px;
  height: 400px;
}

.payout-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payout-text h2 {
  font-size: 30px;
  margin: 0;
}

.payout-text p {
  font-size: 16px;
  color: #b0b0b0;
  margin-top: 10px;
}

.payout-info {
  display: flex;
  /* align-items: center; */
  margin-top: 5px;
}

.payout-item {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-right: 20px;
}

.payout-item h3 {
  margin: 0;
  font-size: 30px;
  color: white;
}

.payout-item p {
  font-size: 20px;
  color: #b0b0b0;
  margin-top: 5px;

}

.divider {
  width: 5px;
  height: 65px;
  margin: 10px 20px 10px 0;
  border-radius: 20px;
}

.payout-image img {
  max-width: 200px; /* Adjust the size of the image */
}

.step-item {
  height: 60px;
  align-content: center;
  background-color: #000;
  padding: 0 30px;
}

.step-item-sub {
  background-color: #101012;
  height: 60px;
  align-content: center;
  padding: 0 30px;
}

.item-content {
  display: flex;
  justify-content: space-around;
}

.description-icon-update {
  position: absolute;
  top: 0;
  left: 10px;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 200px;
}

.balance-content-mobile {
  display: none;
}
.balance-content-web {
  display: block;
}

@media (max-width: 768px) {
  .payout-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .payout-image img {
    max-width: 100px;
  }
}

@media (max-width: 430px) and (max-height: 932px) { 
  .background-upgrade {
    background-size: 100vw 70vh; /* Hình nền sẽ phủ đầy toàn bộ màn hình */
    background-position: top; /* Đảm bảo hình nền luôn nằm ở giữa */
    background-repeat: no-repeat; /* Không lặp lại hình nền */
    min-height: 55vh;
  }

  .content-upgrade {
    padding: 6.25vw 3.125vw;
    margin-top: 7vh;
  }

  .choose-upgrade-title {
    font-size: 5vw;
  }

  .choose-upgrade {
    font-size: 3vw;
    width: 100%;
  }
  
  .main-content {
    padding: 0;
  }

  .content-upgrade-img{
    width: 20vw;
  }
  .content-process-title{
    font-size: 4vw;
  }
  .content-process-target {
    font-size: 3vw !important;
  }
  .content-process-img {
    width: 60%;
  }
  .content-process-rate{
    font-size: 3vw !important;
  }

  .content-process-content{
    font-size: 2vw !important; 
  }

  .scenarios-sub{
    display: none;
  }

  .scenarios-sub-show {

  }

  .see-more {
    display: block;
    font-style: italic; /* Chữ nghiêng */
    text-decoration: underline; /* Gạch chân */
  }

  .main-content-update {
    padding: 0 !important;
  }

  .payout-container-title {
    font-size: 5vw;
  }

  .payout-container {
    height: auto;
  }

  .balance-content-mobile {
    display: block;
  }
  .balance-content-web {
    display: none;
  }
}