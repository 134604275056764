.popup-box-change-sltp {
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    /* Background overlay */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.box-change-sltp {
    position: relative;
    width: 30%;
    padding: 0 20px;
    /* Set a max height for the popup */
    background: #232323;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    overflow: hidden;
    /* Hide overflow on the popup itself */
}

.popup-content-change-sltp {
    /* Set a max height for the content */
    max-height: 70vh;
    overflow-y: auto;
    /* Enable vertical scrolling if the content exceeds the height */
    scrollbar-color: #a0a0a0 #232323;
    scrollbar-width: thin;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
}

.confirm-model-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    /* border: 2px solid #007bff; */
    background-color: #007bff;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
  }
  
  .confirm-model-button:hover {
    color: #fff;
    background-color: #186cab;
    /* border: 2px solid #0056b3; */

  }

  .cancel-model-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    /* border: 2px solid red; */
    background-color: #ff1722;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    margin-left: 10px;
  }
  
  .cancel-model-button:hover {
    color: #fff;
    /* border: 2px solid #8B0000; */
    background-color: #ec505d;
  }
  